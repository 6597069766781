/* styles/logo.css */
.navbar-brand img {
    max-height: 50px;
    width: auto;
    object-fit: contain; /* Asegura que la imagen no se deforme */
    transition: transform 0.3s ease; /* Efecto hover opcional */
  }
  
  .navbar-brand img:hover {
    transform: scale(1.1); /* Agranda ligeramente al pasar el mouse */
  }
  

  #sinterec-logo {
    max-height: 45px; /* Ajusta la altura máxima */
    width: auto; /* Mantén las proporciones del logo */
  }
  
 /* EFECTOS CARD WELCOME USER/CERRAR SESSION */
/* Efectos generales para el card */
#card_welcome {
  border: 2px solid #000; /* Mantén el borde negro */
  border-radius: 12px; /* Bordes redondeados */
  padding: 12px 16px; /* Espaciado interno */
  background-color: rgba(255, 255, 255, 0.9); /* Fondo blanco semitransparente */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra ligera */
  transition: all 0.3s ease-in-out; /* Transición suave */
  opacity: 0.95; /* Reduce la transparencia ligeramente */
  position: relative;
}

/* Efecto hover sin escalado */
#card_welcome:hover {
  background-color: rgba(255, 255, 255, 1); /* Fondo más opaco */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada */
  border: 2px solid transparent;
  background-image: linear-gradient(white, white), linear-gradient(90deg, #d90202, #ffc107, #28a745);
  background-origin: border-box;
  background-clip: content-box, border-box; /* Añade efecto degradado al borde */
}

/* Animación del borde para hover */
#card_welcome:hover::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 12px;
  border: 2px solid transparent;
  background: linear-gradient(90deg, #d90202, #ffc107, #28a745);
  z-index: -1;
  animation: gradient-move 2s infinite;
}

/* Movimiento del degradado */
@keyframes gradient-move {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Estilo del botón de cerrar sesión */
#card_welcome .btn {
  background-color: hsla(0, 0%, 3%, 0.849); /* Color rojo acorde al logo */
  color: #fff; /* Texto blanco */
  border: none; /* Sin borde */
  border-radius: 8px; /* Bordes redondeados */
  padding: 8px 12px; /* Espaciado interno */
  transition: background-color 0.3s ease-in-out; /* Transición en hover */
}

#card_welcome .btn:hover {
  background-color: #c4c1c1d3; /* Rojo más oscuro en hover */
}

#home_socio_ {
  margin-left: 10px;
}